import { graphql, navigate, Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
// import EllipseIcon from '../../static/images/icons/Ellipse.svg';
// import Icon from '../../static/images/icons/arrow1.svg';

const Careers = props => {
  const careers = props.data.careers.edges;
  const { intro } = props.data;

  return (
    <Layout bodyClass="page-careers">
      <SEO title="Careers" />
      {/* <Title intro={intro}>
        <div dangerouslySetInnerHTML={{ __html: intro.html }} />
      </Title> */}

      <div className="container mt-4 mt-md-6 mb-6">
        <div className="row gap-lg-3 flex-wrap flex-md-nowrap">
          <div className="col-12 col-md-6">
            <h2>Work at Anuttacon</h2>
            <p>
              As a team of AI researchers, engineers & creatives, we believe deeply in the potential of AI to create new entertainment experiences and push the boundaries of
              interactive entertainment, that people have never experienced before. To achieve our goals, we move fast, are not afraid of risk and are highly ambitious. We are
              looking for like-minded developers so reach out to us if you share a similar vision.{' '}
            </p>
            <h2>Location</h2>
            <p>
              We are currently hiring primarily in the San Francisco Bay Area, where we have an office in Santa Clara. As a startup, we believe working in-person has advantages,
              but we are open to remote work opportunities for unique candidates.
            </p>
            <h2>Benefits</h2>
            <p>We offer the following employee benefits:</p>
            <p>
              <div>Competitive compensation</div>
              <div>Medical, dental, and vision insurance</div>
              <div>Visa sponsorship for new hires</div>
              <div>Generous paid time off</div>
              <div>Flexible working time</div>
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h2>Open Roles</h2>
          </div>
          {/* {careers.map(edge => (
            <div
              key={edge.node.id}
              className="col-12"
              onClick={() => {
                navigate(edge.node.fields.slug);
              }}
            >
              <div className="service service-summary">
                <div className="service-content">
                  <EllipseIcon className="ellipse"></EllipseIcon>
                  <div>
                    <h2>
                      <Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
                    </h2>
                    <div className="description">{edge.node.frontmatter.description || edge.node.excerpt}</div>
                  </div>
                  <div>
                    <Link to={edge.node.fields.slug} className="btn">
                      <span className="text">View More</span>
                      <span className="icon-container">
                        <Icon className="icon"></Icon>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query CareersQuery {
    careers: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/careers/.*/" } }, sort: { fields: [frontmatter___weight], order: ASC }) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
          }
        }
      }
    }
    intro: markdownRemark(fileAbsolutePath: { regex: "/(careers.md)/" }) {
      html
      frontmatter {
        title
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
      }
    }
  }
`;

export default Careers;
